import { createElement, FC } from 'react';
import {
  ButtonBaseProps,
  ButtonVariant,
  ButtonVariantProps
} from 'legos/button/base-button';
import {
  ButtonRaisedBase as RawButtonRaisedBase,
  ButtonRaisedBaseCCM
} from './button-raised.ccm.css';
import { useButtonTheme } from './button-raised.theme';

export const ButtonRaised: FC<ButtonBaseProps> = ({
  color = 'default',
  ...props
}) => {
  const theme = useButtonTheme();
  const ButtonComponent: FC<ButtonVariantProps<
    ButtonRaisedBaseCCM
  >> = ButtonVariant as any;

  let calcColor = color;
  let background = theme[calcColor].background;
  let textColor = theme[calcColor].textColor;
  if (props.fullWidth && theme.fullWidthColorVariant) {
    calcColor = theme.fullWidthColorVariant;
    background = theme.fullWidthButtonColor || theme[calcColor].background;
    textColor = theme.fullWidthButtonColorText || theme[calcColor].textColor;
  }

  return (
    <ButtonComponent
      variant={RawButtonRaisedBase}
      $background={background}
      $backgroundDisabled={theme[calcColor].backgroundDisabled}
      $backgroundDisabledFocus={theme[calcColor].backgroundDisabledFocus}
      $backgroundFocus={theme[calcColor].backgroundFocus}
      $textDisabled={theme[calcColor].textDisabled}
      $textColor={textColor}
      {...props}
    />
  );
};
