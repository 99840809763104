import { createUseThemeHook, LegoColorPalette, LegoTheme } from 'legos/theme';
import { ColorVariant } from '../base-button';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    buttonRaisedVariant: ButtonTheme;
  }
}

export interface ButtonVariant {
  background: string;
  backgroundDisabled: string;
  backgroundDisabledFocus: string;
  backgroundFocus: string;
  textColor: string;
  textDisabled: string;
}

export interface ButtonTheme {
  default: ButtonVariant;
  accent: ButtonVariant;
  positive: ButtonVariant;
  negative: ButtonVariant;
  special: ButtonVariant;
  /** Sets the border radius for raised, flat and ghost buttons */
  borderRadius: string;
  fullWidthColorVariant?: ColorVariant;
  fullWidthButtonColor?: string; // overrides fullWidthColorVariant button color
  fullWidthButtonColorText?: string; // overrides fullWidthColorVariant button text color
}

const mapColorForButton = (
  global: LegoTheme,
  color: LegoColorPalette
): ButtonVariant => ({
  background: color.color500,
  backgroundDisabled: global.neutral.color100,
  backgroundDisabledFocus: global.neutral.color200,
  backgroundFocus: color.color600,
  textColor: '#ffffff',
  textDisabled: global.neutral.color800
});

const [ButtonThemeProvider, useButtonTheme] = createUseThemeHook(
  'buttonRaisedVariant',
  (global): ButtonTheme => ({
    default: mapColorForButton(global, global.default),
    accent: mapColorForButton(global, global.accent),
    positive: mapColorForButton(global, global.positive),
    negative: mapColorForButton(global, global.negative),
    special: mapColorForButton(global, global.special),
    borderRadius: '2px'
  })
);

export { ButtonThemeProvider, useButtonTheme };
