
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.ButtonRaisedBase_00c1c0d3cd30d340{display:inline-flex;justify-content:center;align-items:center;padding:0 16px;margin:16px 0;min-width:96px;box-sizing:border-box;height:36px;border-radius:2px;border:none;background-color:var(--background_567447c1c0f79ec0);color:var(--textColor_2db134b693ed3782);box-shadow:_shadow2dp;line-height:32px}.ButtonRaisedBase_00c1c0d3cd30d340.noMargins_872f428728bf08f1{margin:0}.ButtonRaisedBase_00c1c0d3cd30d340:focus,.ButtonRaisedBase_00c1c0d3cd30d340:hover{background-color:var(--backgroundFocus_18e46e8bbf49b97c)}.ButtonRaisedBase_00c1c0d3cd30d340:active{box-shadow:from "legos/theme/constants.css"}.ButtonRaisedBase_00c1c0d3cd30d340.disabled_676009882d1afd3c,.ButtonRaisedBase_00c1c0d3cd30d340:disabled{background-color:var(--backgroundDisabled_129439b119ea9537);color:var(--textDisabled_cd351dc5350f269a);box-shadow:none}.ButtonRaisedBase_00c1c0d3cd30d340.fullWidth_d03d9d7d39eb99ad{width:100%}.ButtonRaisedBase_00c1c0d3cd30d340.disabled_676009882d1afd3c:active,.ButtonRaisedBase_00c1c0d3cd30d340.disabled_676009882d1afd3c:focus,.ButtonRaisedBase_00c1c0d3cd30d340.disabled_676009882d1afd3c:hover,.ButtonRaisedBase_00c1c0d3cd30d340:disabled:active,.ButtonRaisedBase_00c1c0d3cd30d340:disabled:focus,.ButtonRaisedBase_00c1c0d3cd30d340:disabled:hover{box-shadow:none;background-color:var(--backgroundDisabledFocus_9dc9324f27cf6ce3)}.ButtonRaisedBase_00c1c0d3cd30d340.borderRadius_c79e386eb4c71d03{border-radius:40px;height:48px;text-transform:none}`;
styleInject(_css)

/** ButtonRaisedBase Props */
export type ButtonRaisedBaseCCM = {
  /** ButtonRaisedBase Component Custom Properties */
  '$background': string;
  '$textColor': string;
  '$backgroundFocus': string;
  '$backgroundDisabled': string;
  '$textDisabled': string;
  '$backgroundDisabledFocus': string;

  /** ButtonRaisedBase Modifier Flags */
  '$noMargins'?: boolean;
  '$disabled'?: boolean;
  '$fullWidth'?: boolean;
  '$borderRadius'?: boolean;
};
/** Base ButtonRaisedBase component */
export const ButtonRaisedBase: ComponentCreator<ButtonRaisedBaseCCM> = createComponentCreator({
  "name": "ButtonRaisedBase",
  "base": "ButtonRaisedBase_00c1c0d3cd30d340",
  "prop": {
    "$background": "--background_567447c1c0f79ec0",
    "$textColor": "--textColor_2db134b693ed3782",
    "$backgroundFocus": "--backgroundFocus_18e46e8bbf49b97c",
    "$backgroundDisabled": "--backgroundDisabled_129439b119ea9537",
    "$textDisabled": "--textDisabled_cd351dc5350f269a",
    "$backgroundDisabledFocus": "--backgroundDisabledFocus_9dc9324f27cf6ce3"
  },
  "mod": {
    "$noMargins": "noMargins_872f428728bf08f1",
    "$disabled": "disabled_676009882d1afd3c",
    "$fullWidth": "fullWidth_d03d9d7d39eb99ad",
    "$borderRadius": "borderRadius_c79e386eb4c71d03"
  }
});

